@import 'styles/theme';

.RelatedPosts {
  margin: theme($spacings, 100) auto;

  @include switchDesktopMobileClasses('md');

  @include from('l') {
    margin: theme($spacings, 250) auto;
  }

  .title,
  .posts {
    grid-column: 1 / -1;

    @include from('md') {
      grid-column: 2 / -2;
    }

    @include from('l') {
      grid-column: 3 / -3;
    }
  }

  .title {
    @include from('md') {
      margin-bottom: theme($spacings, 100);
    }
  }

  .slider {
    width: 100%;

    .post {
      margin-right: theme($mobile, 'gap');
    }
  }

  .buttonWrapper {
    margin: theme($spacings, 50) auto 0;
    @include gridColumnWidth(4, $mobile);

    .button {
      display: block;
    }
  }
}
