@import 'styles/theme';

.Post {
  width: 100%;
  position: relative;

  @include switchDesktopMobileClasses('md');

  @include from('md') {
    display: flex;
    align-items: flex-start;
    position: relative;
    margin-bottom: theme($spacings, 50);
  }

  .content,
  .image {
    display: block;
  }

  .date {
    @include from('md') {
      margin-right: theme($desktop, 'gap');
      @include gridColumnWidth(2, $desktop);
    }
  }

  .image {
    @include setPropertyValue(
      (
        'values': $mobile,
        'propertyName': 'width',
        'gutters': 1,
        'gaps': 0,
        'columns': 4,
      )
    );

    @include from('md') {
      @include gridColumnWidth(3, $desktop);

      @include setPropertyValue(
        (
          'values': $desktop,
          'propertyName': 'margin-right',
          'gutters': 0,
          'gaps': 2,
          'columns': 1,
        )
      );
    }

    @include from('l') {
      @include gridColumnWidth(2, $desktop);

      @include setPropertyValue(
        (
          'values': $desktop,
          'propertyName': 'margin-right',
          'gutters': 0,
          'gaps': 2,
          'columns': 1,
        )
      );
    }
  }

  .content {
    padding-left: theme($mobile, 'gutter');
    @include gridColumnWidth(4, $mobile);

    @include from('md') {
      padding-left: 0;
      @include gridColumnWidth(4, $desktop);
    }

    @include from('l') {
      @include gridColumnWidth(3, $desktop);
    }

    .headline {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-top: theme($spacings, 20);
      line-height: 1.82;

      @include from('md') {
        margin-top: 0;
      }
    }

    .text {
      display: none;

      @include from('md') {
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        margin-top: theme($spacings, 10);
      }
    }

    .link {
      margin-top: theme($spacings, 20);
    }
  }
}
