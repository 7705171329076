@import 'styles/theme';

.ImageFullWidth {
  position: relative;
  width: 100%;
  margin-top: theme($spacings, 75);
  margin-bottom: theme($spacings, 75);

  .wrapperWithLink {
    display: block;
  }

  &.hasLineDecorator {
    .title:before {
      display: block;
    }

    .link,
    .title {
      @include from('md') {
        padding-left: theme($spacings, 80);
      }
    }
  }

  .ratio {
    max-height: 100vh;
    height: 100%;
  }

  @include from('md') {
    margin-top: theme($spacings, 250);
    margin-bottom: theme($spacings, 200);
  }

  &.hasBothImages {
    .landscape {
      display: none;
    }

    @include from('md') {
      .landscape {
        display: block;
      }

      .portrait {
        display: none;
      }
    }
  }

  .textWrapper {
    position: absolute;
    bottom: theme($spacings, 50);

    &.right {
      @include from('md') {
        .title {
          justify-self: right;
        }
        .link {
          justify-self: right;
        }
      }
    }
  }

  .title {
    grid-column: 1 / -1;
    display: inline-flex;
    align-items: center;
    position: relative;
    margin-bottom: theme($spacings, 10);
    justify-self: center;

    &.titleLine {
      &:before {
        width: 50px;
        height: 1px;
        position: absolute;
        background-color: theme($colors, 'gold');
        left: 0;
      }
    }

    @include from('md') {
      justify-self: left;

      &.titleLine {
        padding-left: theme($spacings, 80);
        &:before {
          content: '';
        }
      }
    }
  }

  .link {
    grid-column: 1 / -1;
    justify-self: center;

    @include from('md') {
      justify-self: left;
    }
  }
}
