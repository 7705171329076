@import 'styles/theme';

.FormSlice {
  margin-top: theme($spacings, 75);
  margin-bottom: theme($spacings, 75);
  align-items: flex-start;

  @include from('lg') {
    margin-top: theme($spacings, 200);
    margin-bottom: theme($spacings, 250);
    align-items: flex-start;
  }

  .form {
    grid-row: 2;
    grid-column: 1 / -1;
    margin-top: theme($spacings, 50);

    @include from('lg') {
      position: sticky;
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: theme($desktop, 'gap');
      grid-column: 2 / span 5;
      grid-row: 1;
      margin-top: 0;
    }

    .title {
      grid-column: 1 / -1;
      margin-bottom: theme($spacings, 45);

      @include from('lg') {
        margin-bottom: theme($spacings, 65);
      }
    }

    .submit {
      grid-column: 1 / -1;
      width: 100%;
      margin: theme($spacings, 50) 0 0;
    }

    .input {
      grid-column: 1 / -1;
      width: 100%;

      &.phone {
        margin-top: 0;
      }

      @include from('md') {
        grid-column: span 1;
      }

      &.fullWidth {
        @include from('md') {
          grid-column: 1 / -1;
        }
      }
    }
  }

  .imageWrapper {
    margin-top: theme($spacings, 50);
    grid-column: 1 / -1;
    grid-row: 1;

    @include from('lg') {
      margin-top: 0;
      grid-column: 8 / span 4;
      position: sticky;
    }

    .image {
      position: relative;
      width: 100%;
    }
  }
}
