@import 'styles/theme';

.ImageDyptic {
  margin-top: theme($spacings, 75);
  margin-bottom: theme($spacings, 75);
  align-items: flex-start;

  @include from('md') {
    margin-top: theme($spacings, 250);
    margin-bottom: theme($spacings, 250);
  }

  .imageLeft,
  .imageRight {
    display: block;
  }

  .imageLeft {
    position: relative;
    grid-column: 1 / -1;
    height: fit-content;

    @include from('md') {
      grid-column: 2 / 7;
    }
  }

  .imageRight {
    position: relative;
    grid-column: 1 / -1;
    margin-top: theme($spacings, 25);

    @include from('md') {
      margin-top: 0;
      grid-column: 8 / 12;
    }
  }

  &.inversed {
    .imageLeft {
      grid-column: 1 / -1;
      margin-top: theme($spacings, 25);

      @include from('md') {
        margin-top: 0;
        grid-column: 7 / 12;
      }
    }

    .imageRight {
      grid-row: 1;
      grid-column: 1 / -1;
      margin-top: 0;

      @include from('md') {
        grid-column: 2 / 6;
      }
    }
  }
}
