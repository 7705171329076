@import 'styles/theme';

.ReboundZoneEdito {
  text-align: center;
  margin-top: theme($spacings, 75);
  margin-bottom: theme($spacings, 75);

  @include from('md') {
    margin-top: theme($spacings, 250);
    margin-bottom: theme($spacings, 250);
  }

  .item {
    grid-column: 1 / -1;
    transition: opacity 0.1s ease;
    transition: ease 0.2s opacity;

    @include from('md') {
      grid-column: 2 / 7;
    }

    &:last-child {
      position: relative;
      grid-column: 1 / -1;
      margin-top: theme($spacings, 50);

      @include from('md') {
        margin-top: 0;
        grid-column: 7 / 12;
      }
    }

    .imageWrapper {
      cursor: pointer;
      transition: opacity 0.2s ease;
    }

    &.isHover {
      opacity: 0.3;

      &:hover {
        opacity: 1;
      }
    }
  }

  .title {
    margin: theme($spacings, 30) auto 0;
  }
}
