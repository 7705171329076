@import 'styles/theme';

.TinyPush {
  margin: theme($spacings, 100) auto;

  @include from('md') {
    margin: theme($spacings, 250) auto;
  }
}

.imageContainer {
  grid-column: 1 / 4;
  height: fit-content;
  @include from('md') {
    grid-column: 9 / 11;
  }
}

.contentContainer {
  grid-column: 1 / -2;
  margin-top: theme($spacings, 30);

  @include from('md') {
    grid-row: 1;
    grid-column: 3 / 8;
    margin-top: 0;
  }
}

.line {
  grid-column: 4 / -1;
  grid-row: 1;
  height: 1px;
  background-color: theme($colors, 'gray-93');

  @include from('md') {
    display: none;
  }
}

.text:not(:last-child) {
  margin-bottom: theme($spacings, 30);
}

.reversed {
  .imageContainer {
    grid-column: -4 / -1;

    @include from('md') {
      grid-column: 3 / 5;
    }
  }

  .line {
    grid-column: 1 / span 3;
  }

  .contentContainer {
    grid-column: 2 / -1;
    text-align: right;
    display: flex;
    flex-direction: column;

    @include from('md') {
      grid-column: 6 / 11;
      text-align: left;
    }
  }
}
