@import 'styles/theme';

$hero-title-line-height: theme($spacings, 50);

.HeroMain {
  position: relative;
  width: 100%;
  text-align: center;
  overflow: hidden;

  @include from('md') {
    min-height: 100vh;
    min-height: stableVh(100);
  }

  &.hasLineDecorator {
    .title:before {
      display: block;
    }

    .link,
    .title {
      @include from('md') {
        padding-left: theme($spacings, 80);
      }
    }
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
}

.textWrapper {
  position: absolute;
  bottom: 0;
  width: 100%;
  grid-column: 1 / -1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: {
    top: theme($spacings, 50);
    bottom: theme($spacings, 50);
  }
  box-sizing: border-box;
  z-index: 1;

  @include from('md') {
    padding: {
      right: theme($desktop, 'gutter');
      left: theme($desktop, 'gutter');
    }

    .titleWrapper,
    .link {
      &.left {
        text-align: left;
      }

      &.right {
        text-align: right;
      }
    }
  }
}

.title {
  display: inline-block;
  position: relative;
  margin-bottom: theme($spacings, 10);

  &:before {
    width: 50px;
    height: 1px;
    position: absolute;
    top: calc(#{$hero-title-line-height} / 2);
    left: 0;
    background-color: theme($colors, 'gold');
    display: none;
  }

  @include from('md') {
    line-height: $hero-title-line-height;

    &:before {
      content: '';
    }
  }
}

.ratio {
  height: 100%;
}

.imageWrapper {
  position: relative;
  z-index: 0;

  .wrapperWithLink {
    display: block;
  }

  .landscape {
    @include from('md') {
      height: vh(100);
    }
  }

  &.hasBothImages {
    .landscape {
      display: none;
    }

    @include from('md') {
      .landscape {
        display: block;
      }

      .portrait {
        display: none;
      }
    }
  }
}
