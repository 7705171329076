@import 'styles/theme';

.EditoLongText {
  margin-top: theme($spacings, 75);
  margin-bottom: theme($spacings, 75);

  @include from('lg') {
    margin-top: theme($spacings, 200);
    margin-bottom: theme($spacings, 250);
  }

  .blockText {
    &.hasMargin {
      margin-top: theme($spacings, 50);
    }
  }

  .title {
    margin-top: theme($spacings, 50);
    margin-bottom: theme($spacings, 50);

    @include from('lg') {
      margin-top: 0;
    }
  }

  .title,
  .blockText {
    order: 2;
  }

  .texts,
  .images {
    grid-column: 1 / -1;
  }

  .texts {
    display: flex;
    flex-direction: column;

    @include from('lg') {
      display: block;
      position: sticky;
      grid-column: 2 / span 6;
    }

    .text {
      &:not(:first-child) {
        margin-top: theme($spacings, 20);

        @include from('lg') {
          margin-top: theme($spacings, 30);
        }
      }

      // &::first-letter {
      //   display: inline-block;
      //   margin-left: theme($spacings, 30);
      // }
    }
  }
  .link {
    margin-top: theme($spacings, 50);
    display: block;
  }
}
