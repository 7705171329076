@import 'styles/theme';

.EditoLongImageText {
  margin-top: theme($spacings, 75);
  margin-bottom: theme($spacings, 75);

  @include from('lg') {
    margin-top: theme($spacings, 200);
    margin-bottom: theme($spacings, 250);
  }

  .wrapperWithLink {
    display: block;
  }

  &.inversed {
    .images {
      @include from('lg') {
        grid-row: 1;
        grid-column: 2 / span 4;
      }
    }

    .texts {
      @include from('lg') {
        grid-row: 1;
        grid-column: 8 / span 4;
      }
    }
  }

  .content {
    @include from('lg') {
      align-items: flex-start;
    }
  }

  .blockText {
    &.hasMargin {
      margin-top: theme($spacings, 50);

      @include from('lg') {
        margin-top: theme($spacings, 100);
      }
    }
  }

  .title {
    margin-bottom: theme($spacings, 50);
  }

  .title,
  .blockText {
    order: 2;
  }

  .texts,
  .images {
    grid-column: 1 / -1;
  }

  .texts {
    display: flex;
    flex-direction: column;

    @include from('lg') {
      display: block;
      position: sticky;
      grid-column: 2 / span 4;
    }

    .text {
      &:not(:first-child) {
        margin-top: theme($spacings, 20);

        @include from('lg') {
          margin-top: theme($spacings, 30);
        }
      }

      // &::first-letter {
      //   display: inline-block;
      //   margin-left: theme($spacings, 30);
      // }
    }
    .link {
      margin-top: theme($spacings, 50);
      display: block;
    }
  }

  .mobileImage {
    order: 2;
    margin: theme($spacings, 50) 0;

    &.isFirst {
      order: 1;
      margin-top: 0;
    }

    @include from('lg') {
      display: none;
      margin-bottom: 0;
    }
  }

  .images {
    display: none;

    @include from('lg') {
      display: block;
      position: sticky;
      margin-top: 0;
      grid-column: 8 / span 4;
    }

    .image {
      position: relative;
      width: 100%;
      margin-top: theme($spacings, 50);

      &:first-of-type {
        margin-top: 0;
      }
    }
  }

  .imageText {
    color: theme($colors, 'gray-47');
    margin-top: theme($spacings, 10);

    @include from('md') {
      color: theme($colors, 'gray-20');
      margin-top: theme($spacings, 20);
    }
  }
}
