@import 'styles/theme';

.SelectedStores {
  margin: theme($spacings, 100) auto;

  @include switchDesktopMobileClasses('md');

  @include from('l') {
    margin: theme($spacings, 250) auto;
  }

  .title,
  .stores {
    grid-column: 1 / -1;

    @include from('md') {
      grid-column: 2 / -2;
    }

    @include from('l') {
      grid-column: 3 / -3;
    }
  }

  .title {
    border: none;
  }

  .store {
    display: flex;
    justify-content: space-between;
    margin-bottom: theme($spacings, 50);

    @include from('md') {
      margin-bottom: theme($spacings, 75);
    }

    &:last-child {
      margin-bottom: 0;
    }

    .content {
      position: relative;
      padding-top: theme($spacings, 20);

      @include gridColumnWidth(4, $mobile);

      @include from('md') {
        padding-top: theme($spacings, 50);
        @include gridColumnWidth(4, $desktop);
      }

      @include from('l') {
        @include gridColumnWidth(3, $desktop);
      }

      &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        height: 1px;
        background-color: theme($colors, 'gray-93');
        @include gridColumnWidth(4, $mobile);

        @include from('md') {
          @include gridColumnWidth(7, $desktop);
        }

        @include from('l') {
          @include gridColumnWidth(6, $desktop);
        }
      }

      .contactStore {
        margin-top: theme($spacings, 10);

        @include from('md') {
          margin-top: theme($spacings, 30);
        }

        .link {
          display: block;
        }
      }
    }

    .image {
      @include gridColumnWidth(2, $mobile);

      @include from('md') {
        @include gridColumnWidth(3, $desktop);
      }

      @include from('l') {
        @include gridColumnWidth(2, $desktop);
      }
    }
  }
}
