@import 'styles/theme';

.LargeText {
  text-align: center;
  margin: theme($spacings, 100) auto;

  @include from('md') {
    margin: theme($spacings, 200) auto;
  }
}

.text {
  grid-column: 1 / -1;

  @include from('md') {
    grid-column: 2 / -2;
  }
}
