@import 'styles/theme';

.PushLinks {
  overflow: hidden;
  margin: theme($spacings, 100) auto;

  @include from('md') {
    margin: theme($spacings, 250) auto;
  }

  .wrapper {
    align-items: center;
  }

  .title {
    margin-bottom: theme($spacings, 20);
  }

  .title,
  .link {
    transition: ease 0.2s opacity;
  }

  .links {
    display: none;

    @include from('md') {
      display: block;
      grid-column: 2 / span 3;
    }
  }

  .section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    &:not(:last-child) {
      margin-bottom: theme($spacings, 50);
    }
  }

  .titleSection {
    display: flex;
    flex-direction: row;
    position: relative;
  }

  .titleEntry {
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    transition: ease 0.5s opacity;

    &.active {
      position: relative;
      opacity: 1;
    }
  }

  .imageWrapper {
    display: none;

    @include from('md') {
      display: block;
      grid-column: 5 / -1;
      margin-right: calc(#{theme($desktop, 'gutter')} * -1);
    }
  }

  .sliderContainer {
    width: 100vw;
  }

  .slider {
    grid-column: 1 / -1;

    @include from('md') {
      display: none;
    }
  }

  .card {
    margin-right: theme($mobile, 'gap');
    @include gridColumnWidth(5, $mobile);
  }

  .sliderLinksWrapper {
    position: relative;
    grid-column: 1 / -1;
    padding-left: theme($mobile, 'gutter');
    box-sizing: border-box;
    margin: {
      top: theme($spacings, 30);
      left: calc(#{theme($mobile, 'gutter')} * -1);
      right: calc(#{theme($mobile, 'gutter')} * -1);
    }
    overflow: hidden;

    @include from('md') {
      display: none;
    }

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      width: calc(#{theme($mobile, 'gutter')});
      z-index: 1;
      background: linear-gradient(
          90deg,
          rgba(255, 255, 255, 1),
          rgba(255, 255, 255, 0)
        ),
        linear-gradient(
          to right,
          rgba(255, 255, 255, 1),
          rgba(255, 255, 255, 0) 60%
        );
    }
  }

  .sliderLinks {
    margin-top: theme($spacings, 10);
    display: inline-flex;
  }

  .sliderLink {
    padding-right: theme($spacings, 20);
    white-space: nowrap;
    box-sizing: border-box;
    opacity: 0.3;
    transition: ease 0.2s opacity;
    cursor: pointer;

    &.isActive {
      opacity: 1;
    }
  }

  .isHover {
    @include from('md') {
      opacity: 0.3;

      &:hover {
        opacity: 1;
      }
    }
  }
}

.bounds {
  width: calc(#{getGridColumnWidth(1, $mobile)} + #{theme($mobile, 'gap')});
}
