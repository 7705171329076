@import 'styles/theme';

.VideoPlayer {
  line-height: 0;
  padding: 0;
  margin: theme($spacings, 100) auto;

  @include from('md') {
    margin: theme($spacings, 250) auto;
    padding: 0 calc(#{theme($desktop, 'gutter')});
  }

  &.fullscreen {
    @include from('md') {
      padding: 0;

      .wrapper {
        grid-column: 1 / -1;
      }
    }
  }

  &.controls {
    .controlsWrapper {
      display: none;
    }
  }
}

.VideoMock {
  margin: theme($spacings, 100) auto;
  padding: 0;

  @include from('md') {
    margin: theme($spacings, 250) auto;
    padding: 0 calc(#{theme($desktop, 'gutter')});
  }

  &.fullscreen {
    padding: 0;

    .image {
      grid-column: 1 / -1;

      @include from('md') {
        grid-column: 1 / -1;
      }
    }
  }

  .image {
    position: relative;
    grid-column: 1 / -1;

    @include from('md') {
      grid-column: 3 / -3;
    }
  }
}
